import { lazy, ReactElement } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Layout } from 'components/layout';
import { NotFoundPage } from 'features/notFoundPage';
import { useAuth } from 'hooks';
import { ROUTE_PATHS } from 'settings';

import { ProtectedRoute } from './components';

const LoginPage = lazy(() =>
  import('features/login').then(({ LoginPage }) => ({ default: LoginPage })),
);

const ContractorsPage = lazy(() =>
  import('features/contractors').then(({ ContractorsPage }) => ({ default: ContractorsPage })),
);

const ProfilePage = lazy(() =>
  import('features/contractors').then(({ ProfilePage }) => ({ default: ProfilePage })),
);

const AddEditContractorPage = lazy(() =>
  import('features/contractors').then(({ AddEditContractorPage }) => ({
    default: AddEditContractorPage,
  })),
);

const PaymentRequiredPage = lazy(() =>
  import('features/paymentRequired').then(({ PaymentRequiredPage }) => ({
    default: PaymentRequiredPage,
  })),
);

const AppInfoPage = lazy(() =>
  import('features/appInfo').then(({ AppInfoPage }) => ({
    default: AppInfoPage,
  })),
);

const ImportUsersSpreadsheetPage = lazy(() =>
  import('features/importUsersSpreadsheet').then(({ ImportUsersSpreadsheetPage }) => ({
    default: ImportUsersSpreadsheetPage,
  })),
);

const { ROOT, AUTHORIZATION, CONTRACTORS, PAYMENT_REQUIRED, APP_INFO, IMPORT_USERS_SPREADSHEET } =
  ROUTE_PATHS;

function AppRoutes(): ReactElement {
  const { isAuth } = useAuth();

  return (
    <Routes>
      <Route element={<ProtectedRoute isAllowed={!isAuth} redirectPath={ROOT} />}>
        <Route path={AUTHORIZATION.SIGNIN.INDEX} element={<LoginPage />} />
      </Route>
      <Route path={ROOT} element={<Layout />}>
        <Route
          element={<ProtectedRoute isAllowed={isAuth} redirectPath={AUTHORIZATION.SIGNIN.INDEX} />}
        >
          <Route index element={<Navigate to={CONTRACTORS.INDEX} replace />} />
          <Route path={CONTRACTORS.INDEX} element={<ContractorsPage />} />
          <Route path={CONTRACTORS.BY_ID} element={<ProfilePage />} />
          <Route path={CONTRACTORS.ADD_CONTRACTOR} element={<AddEditContractorPage />} />
          <Route path={CONTRACTORS.EDIT_CONTRACTOR} element={<AddEditContractorPage />} />
          <Route path={IMPORT_USERS_SPREADSHEET.INDEX} element={<ImportUsersSpreadsheetPage />} />
        </Route>
      </Route>
      <Route path={PAYMENT_REQUIRED.INDEX} element={<PaymentRequiredPage />} />
      <Route path={APP_INFO.INDEX} element={<AppInfoPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AppRoutes;
