export const photoFormatRule = /\.(jpg|png)/g;

export const firstNameRule = /^[a-zA-Z\-]+$/;

export const lastNameRule = /^[a-zA-Z\-]+$/;

export const emailRule = /^[0-9a-zA-Z_\-@.]+$/;

export const phoneNumberRule = /^\+[0-9]*$/;

export const spreadsheetCellRule = /^[a-zA-Z]+[0-9]+$/;
export const spreadsheetTableIdRule = /^[a-zA-Z0-9-_]+$/;
